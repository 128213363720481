import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Section from '../components/Section/Section';
import ContactTeaser from '../components/ContactTeaser/ContactTeaser';
import IconPhone from '@iconscout/react-unicons/icons/uil-phone';
import IconEnvelope from '@iconscout/react-unicons/icons/uil-envelope';
import IconMapMarker from '@iconscout/react-unicons/icons/uil-map-marker';

const Kontakt = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo
        title="Kontakt"
        location={location}
        meta={[{ name: 'robots', content: 'noindex, nofollow' }]}
      />
      <header className="ContentHeader">
        <div className="inner-wrapper">
          <h1 itemProp="headline">Kontakt</h1>
        </div>
      </header>
      <Section>
        <div>
          <p>
            <strong>Silja Tyllilä</strong>
            <br />
            <IconPhone className={'Footer__icon'} />
            <a className="Link" href="tel:+4915120150269">
              0151.20.15.02.69
            </a>
            <br />
            <IconEnvelope className={'Footer__icon'} />
            <a className="Link" href="mailto:mail@siljaty.com">
              mail@siljaty.com
            </a>
          </p>
          <p>
            <strong>Adresse Praxis</strong>
            <br />
            Arnswaldtstraße 6
            <br />
            30159 Hannover
            <br />
            <IconMapMarker className={'Footer__icon'} />
            <a href="https://www.google.de/maps/place/Arnswaldtstra%C3%9Fe+6,+30159+Hannover" target="_blank">
              Auf Google Maps anzeigen
            </a>
          </p>
        </div>
      </Section>
      <Section>
        <ContactTeaser />
      </Section>
    </Layout>
  );
};

export default Kontakt;
